export const getDefaultOffersBoxStyles = (theme) => (
  {
    consentBox: {
      margin: 0,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.slate.main,
      padding: '24px 24px 48px',
      [theme.breakpoints.up('md')]: {
        margin: '74px 0',
        padding: '50px 60px',
      },
    },
    consentHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: 24,
    },
    consentHeaderImg: {
      width: '25%',
      paddingRight: '5%',
      '& img': {
        display: 'block',
        height: 'auto',
        maxWidth: '100%',
      },
    },
    avatar: {
      height: 52,
      width: '25%',
      paddingLeft: '5%',
      borderLeft: '1px solid #B2BCCA',
      display: 'flex',
      borderRadius: 0,
      '& img': {
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: '5%',
      },
      [theme.breakpoints.up('md')]: {
        height: 76,
      },
    },
    consentTopTitle: {
      color: theme.palette.slate.main,
      fontSize: '24px',
      lineHeight: '29px',
      marginBottom: 16,
      letterSpacing: '-0.05em',
    },
    consentTitle: {
      fontSize: '18px',
      lineHeight: '29px',
      marginBottom: 24,
    },
    consentTitleSmallMargin: {
      marginBottom: 8,
    },
    consentSubTitle: {
      color: '#677796',
      fontSize: '14px',
      lineHeight: '22px',
      marginBottom: 48,
    },
    consentList: {
      padding: 0,
      listStyle: 'none',
      marginBottom: 48,
      [theme.breakpoints.up('md')]: {
        marginBottom: 60,
      },
    },
    consentListItem: {
      marginBottom: 16,
      fontWeight: 700,
      lineHeight: 1.5,
      '& span': {
        display: 'block',
        fontSize: '87.5%',
        color: '#677796',
        fontWeight: 400,
      },
    },
    consentAllow: {
      display: 'block',
      backgroundColor: theme.palette.slate.main,
      borderRadius: 2,
      padding: 15,
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 700,
      textTransform: 'none',
      marginTop: 48,
      '&:hover': {
        backgroundColor: theme.palette.darkGrey.main,
      }
    },
    consentDeny: {
      display: 'block',
      color: theme.palette.slate.main,
      textTransform: 'none',
      textDecoration: 'underline',
      fontSize: 14,
      lineHeight: '22px',
      fontWeight: 700,
      cursor: 'pointer',
      marginTop: 16,
      '&:hover': {
        backgroundColor: 'transparent',
      }
    },
    consentDenyCenter: {
      textAlign: 'center',
    },
  }
)
