import React, {useEffect, useState} from 'react'
import {loadUserInfo, login} from "../../../actions/hydra"
import {givenName} from "../../../const/const"
import {endPopupProcessing} from "../../../actions/popup";
import Preloader from "../../Preloader";
import Error from "../../Errors";


const Callback = (props) => {
  const {queryParams} = props

  const [userData, setUserData] = useState(false)
  const [error, setError] = useState()

  useEffect(() => {
    if (queryParams?.clientId) {
      // Get user data details.
      getOffer()
    }
    // eslint-disable-next-line
  }, [queryParams])

  useEffect(() => {
    if (userData) {
      endProcessing(error)
    }
    // eslint-disable-next-line
  }, [userData])

  /**
   * Set error helper.
   *
   */
  const endProcessing = (error = null) => {
    if (error) {
      setError(error)
    } else {
      endPopupProcessing(userData, error);
    }
  }

  /**
   * Load User Info.
   *
   * @returns {Promise<void>}
   */
  async function getUserData() {
    const userInfoRaw = await loadUserInfo(queryParams?.clientId, queryParams.code, queryParams?.premium)
      .catch(error => setError(error.message))

    let userInfo
    if (userInfoRaw?.status === 200 && userInfoRaw?.data) {
      userInfo = userInfoRaw?.data
      if (userInfo?.error) {
        endProcessing(userInfo.error)
      } else if (userInfo?.payload) {
        const profile = userInfo?.payload?.profile
        // Pass user info to parent app.
        setUserData(userInfo.payload)
        // Save given_name for logout.
        if (window.localStorage) {
          window.localStorage.setItem(givenName, profile?.given_name)
        }
      } else {
        endProcessing("Wrong response structure")
      }
    } else if (userInfoRaw?.statusText) {
      endProcessing(userInfoRaw?.statusText)
    } else {
      endProcessing("Unknown error occurred.")
    }
  }

  async function checkLoggedIn(id) {
    return login(id)
  }

  const getOffer = async () => {
    if (queryParams) {
      if (queryParams?.code && queryParams?.clientId) {
        // Process initial load from Hydra.
        getUserData()
      } else if (queryParams.login && queryParams?.clientId) {
        // Process load after plan purchase.
        const userData = await checkLoggedIn(queryParams?.clientId)
        if (userData?.data?.payload) {
          setUserData(userData?.data?.payload)
        } else {
          endProcessing("Unknown login error, please try again.")
        }
      } else if (queryParams?.error && queryParams?.error_description) {
        // Process error.
        const errorDescription = queryParams?.error_description.replaceAll('+', ' ')
        endProcessing(errorDescription)
      }
    }
  }

  return (
    <>
      {error
        ? <Error message={error}/>
        : <Preloader title="Loading data"/>
      }
    </>
  )
}

export default Callback
