/**
 * Get current environment.
 */
export const getEnv = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
    case 'prod':
      return process.env.REACT_APP_ENV;
    case 'stage':
      return 'stg';
    default:
      return 'dev';
  }
}

/**
 * Helper to get App URL.
 *
 * @return {string}
 */
export const getAppUrl = (name) => {
  let url = `https://${name}`;
  const envs = ['dev', 'stage'];
  if (envs.indexOf(process.env.REACT_APP_ENV) !== -1) {
    url += process.env.REACT_APP_ENV === 'stage' ? '-stg' : '-dev';
  }
  return url + '.wesalute.com';
};

/**
 * Get segment key helper.
 *
 * @return {string}
 */
export const getSegmentKey = () => {
  const keys = {
    dev: "1vhAxv6awX0BvdRnTa6pEMrokfvUe2UN",
    stg: "1vhAxv6awX0BvdRnTa6pEMrokfvUe2UN",
    prod: "5mGdvcjLxpX5HXgtSfJrgK0XyB8uGT4J",
  }

  return keys[getEnv()];
}