import React from 'react';
import {Box, Button, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {getDefaultOffersBoxStyles} from "../OffersScreen/styles/box";
import Header from "../Common/Header";

const Error = ({message}) => {
  const useStyles = makeStyles(theme => getDefaultOffersBoxStyles(theme))
  const classes = useStyles()

  const headerTitle = 'Error Occurred'
  const closeLinkText = 'Close'

  return (message &&
    <Box p={{xs: 2, md: 5}} boxShadow={{xs: 0, sm: 0, md: 6}} className={classes.consentBox}>
      <Header/>
      <Typography className={classes.consentTopTitle}>
        {headerTitle}
      </Typography>
      <Typography className={classes.consentTitle}>
        {message}
      </Typography>
      <Button
        className={classes.consentAllow}
        variant="contained"
        color="primary"
        disableElevation
        fullWidth
        onClick={() => window?.close()}
      >
        {closeLinkText}
      </Button>
    </Box>
  );
};

export default Error;