/**
 * Helper to filter offers by audience.
 *
 * @param {object} item
 * @param {string} audience
 * @param {string|null} tier
 * @return {*|boolean}
 */
const checkRedemptionsAccess = (item, audience, tier = null) => {
  if (!item?.redemptions) {
    console.error(`Empty redemption access ${item.nid}`)
    return false
  }
  const filteredItems = item?.redemptions?.filter(redemptionItem => {
    if (!tier) {
      return redemptionItem?.eligibility?.some(item =>
        item?.audience && audience && (item?.audience === audience || item.audience === "military.*")
      )
    }
    return redemptionItem?.eligibility?.some(item =>
      item?.audience && audience && (item?.audience === audience || item.audience === "military.*") &&
      item?.tier && tier && (item?.tier === tier || item.tier === "*")
    )

  })
  return filteredItems.length > 0
}
module.exports = checkRedemptionsAccess;
