import React, {useEffect, useState} from "react"
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"
import Preloader from './components/Preloader';
import {
  Callback,
  Consent,
  PremiumOnlyOffer,
  Home,
} from "./components/pages";
import NotFound from "./components/Common/NotFound";
import * as routes from "./const/routes";
import {styles} from "./styles"
import {Container} from "@mui/material";

const theme = createTheme(styles.theme);

function App(props) {
  const [queryParams, setQueryParams] = useState()

  /**
   * Populate query params.
   */
  useEffect(() => {
    // Process required query params.
    let urlQuery = {}
    const urlQueryParams = new URLSearchParams(window.location.search)
    for (const [key, value] of urlQueryParams.entries()) {
      urlQuery[key] = value
    }
    setQueryParams(urlQuery)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
        { !queryParams
          ? <Preloader title={"Loading..."}/>
          : <Container fixed maxWidth={'md'} className={"app"} disableGutters>
              <BrowserRouter>
                <Routes>
                  <Route path={routes.HOME} element={<Home queryParams={queryParams}/>}/>
                  <Route path={routes.REDIRECT} element={<PremiumOnlyOffer queryParams={queryParams}/>}/>
                  <Route path={routes.CONSENT} element={<Consent queryParams={queryParams}/>}/>
                  <Route path={routes.CALLBACK} element={<Callback queryParams={queryParams}/>}/>
                  <Route path='*' element={<NotFound/>}/>
                </Routes>
              </BrowserRouter>
            </Container>
        }
    </ThemeProvider>
  );
}

export default App;
