import React from 'react';
import {Box, Button, Link, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {getDefaultOffersBoxStyles} from "./styles/box";
import Preloader from "../Preloader";
import Header from "../Common/Header";

const NoOfferMessage = (props) => {

  const {
    name,
    logo,
    performRedirect,
  } = props;

  const useStyles = makeStyles(theme => getDefaultOffersBoxStyles(theme))
  const classes = useStyles()

  const headerTitle = 'Aw, bummer...'
  const headerTextTop = `${name} does not have active offers at this time.`
  const headerTextBottom = 'Please check back next time, or  sign up now to automatically apply new offers in the future, while also gaining access to hundreds of other discounts.'
  const getVetrewardsButtonText = "Start Saving"
  const CancelLinkText = "Cancel"

  return (
    (!name)
      ? <Preloader title="Loading ..."/>
      : <Box p={{xs: 2, md: 5}} boxShadow={{xs: 0, sm: 0, md: 6}} className={classes.consentBox}>
        <Header logo={logo}/>
        <Typography className={classes.consentTopTitle}>
          {headerTitle}
        </Typography>
        <Typography className={classes.consentTitle}>
          {headerTextTop}
        </Typography>
        <Typography className={classes.consentTitle}>
          {headerTextBottom}
        </Typography>
        <Button
          className={classes.consentAllow}
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          onClick={performRedirect}
        >
          {getVetrewardsButtonText}
        </Button>
        <Link
          className={`${classes.consentDeny} ${classes.consentDenyCenter}`}
          color="primary"
          onClick={() => window?.close()}
        >
          {CancelLinkText}
        </Link>
      </Box>
  );
};
export default NoOfferMessage;
