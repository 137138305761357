import axios from "axios"
import {getAppUrl} from "./env";
import {CALLBACK} from "../const/routes";

/**
 * Get client config.
 *
 * @param {string} clientId
 * @returns {Promise<*|null>}
 */
export async function getClientConfig(clientId) {
  const getConfigUrl = `/api/config?clientId=${clientId}`
  // Used fetch to prevent cookies appearance in request.
  // Since it is breaking cache.
  const response = await fetch(getConfigUrl, {
    method: "GET",
    credentials: "omit"
  })
    .catch(error => console.error(error))
  const data = await response.json()
  return data ?? null
}

/**
 * Get redirect URL helper.
 *
 * @param {string} clientId
 * @param {boolean} premium
 * @returns {Promise<*|null>}
 */
export async function getRedirect(clientId, premium) {
  let getRedirectUrl = `/api/redirect?clientId=${clientId}`
  if (premium) {
    getRedirectUrl += `&premium=${premium}`
  }

  const {data} = await axios.get(getRedirectUrl)
    .catch(error => console.error(error))

  return data?.redirect ?? null
}

/**
 * Load user info helper.
 *
 * @param {string} clientId
 * @param {string} code
 * @param {boolean} premium
 * @returns {Promise<*|null>}
 */
export async function loadUserInfo(
  clientId,
  code,
  premium = false
) {
  return await axios.post(`/api/callback`, {
    clientId: clientId,
    code: code,
    premium: premium
  })
    .catch(error => console.error(error))
}

/**
 * Load user if already logged in.
 *
 * @param {string} clientId
 * @returns {Promise<*|null>}
 */
export async function login(clientId) {
  return await axios.get(`/api/login?clientId=${clientId}`)
    .catch(error => console.error(error))
}

/**
 * Logout user.
 *
 * @param {string} clientId
 * @returns {Promise<*|null>}
 */
export async function logout(clientId) {
  return await axios.get(`/api/logout-brand-connections?clientId=${clientId}`)
    .catch(error => console.error(error))
}

/**
 * Remove user's session by clientId.
 *
 * @param {string} clientId
 * @returns {Promise<*|null>}
 */
export async function revokeUsersSession(clientId) {
  let revokeSessionUrl = `/api/session-revoke?clientId=${clientId}`

  await axios.get(revokeSessionUrl)
    .catch(error => console.error(error))

  return true;
}

/**
 * Get premium discount helper.
 *
 * @url {string} URL where return user after checkout.
 * @return {Promise<void>}
 */
export const getVetrewards = async (url) => {
  const returnUrl = url ?? window.location.href;
  window.location.href = getAppUrl('checkout') +
    `?return_to=${encodeURIComponent(returnUrl)}&iframe=1`;
}

/**
 * Get premium discount helper.
 *
 * @param clientId
 * @return {Promise<void>}
 */
export const getCallbackVetrewards = async (clientId) => {
  const returnUrl = window.location.origin + `/${CALLBACK}/?clientId=${clientId}&login=true`;
  window.location.href = getAppUrl('checkout') +
    `?return_to=${encodeURIComponent(returnUrl)}&iframe=1`;
}

// Hydra action helper.
export const doHydraAction = async ({token, type}) => {
  return await axios.post('/api/process-consent', {
    token,
    type
  })
    .catch(error => console.error(error))
};