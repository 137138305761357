/**
 * Check if user is a primary.
 *
 * @param accountData
 * @returns {boolean|undefined|boolean}
 */
const isPrimary = (accountData) => {
  // TODO remove roles once.
  return accountData?.roles
    ? accountData?.roles.some((role) => role.indexOf('vr_') !== -1)
    : false;
}

/**
 * Helper to get Audience.
 *
 * @param accountData
 * @return {string}
 */
export const getAudience = (accountData) => {
  return 'military.' + accountData?.identification?.military?.affinity;
};

export const getUserProfile = (accountData) => {
  return {
    "profile": {
      "sub": accountData?.memberId,
      "member_id": accountData?.memberId,
      "email": accountData?.email,
      "email_verified": accountData?.emailVerified,
      "name": `${accountData?.firstname} ${accountData?.lastname}`,
      "given_name": accountData?.firstname,
      "family_name": accountData?.lastname,
      "tier": isPrimary(accountData) ? "premium" : "basic",
      "audience": [
        getAudience(accountData),
      ],
    }
  }
}
