import React, {useEffect} from 'react';
import {Box, Button, Link, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {getDefaultOffersBoxStyles} from "./styles/box";
import Preloader from "../Preloader";
import Header from "../Common/Header";

const PremiumCommunityOffersCongrats = (props) => {
  const {
    name,
    logo,
    endProcessing,
    exclusiveOfferTitle,
    communityOfferTitle,
    getVetrewards,
  } = props;

  const useStyles = makeStyles(theme => getDefaultOffersBoxStyles(theme))
  const classes = useStyles()

  const headerTitle = 'Congratulations!'
  const headerText = `Your account is now connected for automatic savings with ${name}`
  const continueToText = `Continue to ${name}`
  const getVetrewardsTitle = 'Save more with WeSalute+'
  const getVetrewardsButtonText = "Upgrade to WeSalute+"

  useEffect(() => {
    // Add class for setting background.
    document.body.classList.add('page-consent')
    // Remove class for setting background.
    return () => document.body.classList.remove('page-consent')
  }, [])

  return (
    (!exclusiveOfferTitle || !communityOfferTitle || !name || !logo)
      ? <Preloader title="Loading ..."/>
      : <Box p={{xs: 2, md: 5}} boxShadow={{xs: 0, sm: 0, md: 6}} className={classes.consentBox}>
        <Header logo={logo}/>
        <Typography className={classes.consentTopTitle}>
          {headerTitle}
        </Typography>
        <Typography className={classes.consentTitle}>
          {headerText}
        </Typography>
        <Typography className={classes.consentSubTitle}>
          {communityOfferTitle}
          <Link
            className={classes.consentDeny}
            color="primary"
            onClick={() => endProcessing()}
          >
            {continueToText}
          </Link>
        </Typography>
        <Typography className={`${classes.consentTitle} ${classes.consentTitleSmallMargin}`}>
          {getVetrewardsTitle}
        </Typography>
        <Typography className={classes.consentSubTitle}>
          {exclusiveOfferTitle}
        </Typography>
        <Button
          className={classes.consentAllow}
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          onClick={getVetrewards}
        >
          {getVetrewardsButtonText}
        </Button>
      </Box>
  );
};

export default PremiumCommunityOffersCongrats;
