import React from 'react';
import {Avatar} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {getDefaultOffersBoxStyles} from "../../OffersScreen/styles/box";

const Header = ({logo}) => {
  const useStyles = makeStyles(theme => getDefaultOffersBoxStyles(theme))
  const classes = useStyles()

  return (
    <div className={classes.consentHeader}>
      <div className={classes.consentHeaderImg}>
        <img alt="logo" src='/assets/images/logo.png'/>
      </div>
      {logo ? (
        <Avatar src={logo} className={classes.avatar}/>
      ) : null}
    </div>
  );
};

export default Header;