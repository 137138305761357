import React, {useEffect} from 'react';
import {Box, Button, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {getDefaultOffersBoxStyles} from "./styles/box";
import Preloader from "../Preloader";
import Header from "../Common/Header";

const NoOfferCongrats = (props) => {

  const {
    name,
    logo,
    endProcessing,
  } = props;

  const useStyles = makeStyles(theme => getDefaultOffersBoxStyles(theme))
  const classes = useStyles()

  const headerTitle = 'Congratulations!'
  const headerTextTop = `Your account is now connected for automatic savings with future offers<sup>*</sup> from ${name}.`
  const headerTextBottom = `Be sure to check out other savings available to you in the WeSalute Marketplace!`

  const continueToText = `Continue to ${name}`

  useEffect(() => {
    // Add class for setting background.
    document.body.classList.add('page-consent')
    // Remove class for setting background.
    return () => document.body.classList.remove('page-consent')
  }, [])

  return (
    (!name || !logo)
      ? <Preloader title="Loading ..."/>
      : <Box p={{xs: 2, md: 5}} boxShadow={{xs: 0, sm: 0, md: 6}} className={classes.consentBox}>
        <Header logo={logo}/>
        <Typography className={classes.consentTopTitle}>
          {headerTitle}
        </Typography>
        <Typography className={classes.consentTitle} dangerouslySetInnerHTML={{__html: headerTextTop}}/>
        <Typography className={classes.consentTitle}>
          {headerTextBottom}
        </Typography>
        <Button
          className={classes.consentAllow}
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          onClick={endProcessing}
        >
          {continueToText}
        </Button>
      </Box>
  );
};

export default NoOfferCongrats;
