import React from 'react';
import {Box, Button, Link, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {getDefaultOffersBoxStyles} from "./styles/box";
import Preloader from "../Preloader";
import Header from "../Common/Header";


const PremiumOnlyOfferMessage = (props) => {

  const {
    name,
    logo,
    offerTitle,
    getVetrewards,
    cancelProcessing,
  } = props;

  const useStyles = makeStyles(theme => getDefaultOffersBoxStyles(theme))
  const classes = useStyles()

  const headerText = `The ${name} offer is exclusive to WeSalute+ premium subscribers. Sign up now and save with hundreds of offers.`
  const getVetrewardsButtonText = "Get WeSalute+"
  const CancelLinkText = "Cancel"

  return (
    (!name || !offerTitle)
      ? <Preloader title="Loading ..."/>
      : <Box p={{xs: 2, md: 5}} boxShadow={{xs: 0, sm: 0, md: 6}} className={classes.consentBox}>
        <Header logo={logo}/>
        <Typography className={classes.consentTitle}>
          {headerText}
        </Typography>
        <Typography className={classes.consentSubTitle}>
          {offerTitle}
        </Typography>
        <Button
          className={classes.consentAllow}
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          onClick={getVetrewards}
        >
          {getVetrewardsButtonText}
        </Button>
        <Link
          className={`${classes.consentDeny} ${classes.consentDenyCenter}`}
          color="primary"
          onClick={cancelProcessing ? cancelProcessing : () => window?.close()}
        >
          {CancelLinkText}
        </Link>
      </Box>
  );
};

export default PremiumOnlyOfferMessage;
