/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import '../../../styles/consent.css';

import { isFullyVerified } from '../../../actions/identification';
import {getDefaultOffersBoxStyles} from "../../OffersScreen/styles/box";
import {doHydraAction} from "../../../actions/hydra";
import {getAppUrl, getSegmentKey} from "../../../actions/env";
import Header from "../../Common/Header";
import * as analytics from "../../../actions/analytics";
import Error from "../../Errors";
import Preloader from "../../Preloader";
import {CallbackScreen} from "../../OffersScreen";
import {getUserProfile} from "../../../actions/profileAdapter";

const Consent = (props) => {
  const useStyles = makeStyles(theme => {
    const defaultStyles = getDefaultOffersBoxStyles(theme);
    return {
      ...defaultStyles,
      consentTitle: {
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: 1.2,
        margin: '0 0 24px',
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
          fontSize: '32px',
        },
      },
      consentAllow: {
        ...defaultStyles.consentAllow,
        marginTop: 0,
        marginBottom: 10,
      },
      consentDeny: {
        ...defaultStyles.consentDeny,
        marginTop: 0,
        lineHeight: 1.75,
      },
    }
  });
  const classes = useStyles()

  const { queryParams } = props;

  const [showConsentScreen, setShowConsentScreen] = useState(false);
  const [consentRedirectUrl, setConsentRedirectUrl] = useState();
  const [clientApp, setClientApp] = useState('App');
  const [clientName, setClientName] = useState('');
  const [clientLogo, setClientLogo] = useState('');
  const [verifyRequired, setVerifyRequired] = useState(false);
  const [scope, setScope] = useState([]);
  const [userData, setUserData] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [hydraParams, setHydraParams] = useState(null);
  const [error, setError] = useState();

  // Prepare text for Consent screen.
  let consentHeaderText = '@app is requesting access to your:';
  consentHeaderText = consentHeaderText.replace(
    '@app',
    clientName || clientApp
  );

  let verifyHeaderText = 'To redeem this offer you must verify your military status.';
  let verifyButtonText = 'Verify Now';

  const denyAccessText = 'Cancel';
  const allowAccessText = 'Authorize Access';
  const consentError = 'Error checking user consent';

  // Protect from unauthorized access.
  useEffect(() => {
    document.body.classList.add('page-consent');

    if (
      window.analytics &&
      (typeof window.analytics.initialized === 'undefined' ||
        !window.analytics.initialized)
    ) {
      const segmentKey = getSegmentKey();
      analytics.load(segmentKey);
    }

    if (!queryParams?.consent_challenge) {
      setError("Empty consent challenge.")
    }

    return () => document.body.classList.remove('page-consent');
    // eslint-disable-next-line
  }, [])

  // Check consent token.
  useEffect(() => {
    if (queryParams?.consent_challenge) {
      // Prepare parameters to verify consent challenge.
      const requestParams = {
        token: queryParams?.consent_challenge,
        type: 'load',
      };

      doHydraAction(requestParams)
        .then(({data}) => {
          const {params, accountData} = data;
          setUserData(accountData);
          setHydraParams(params);
          const userProfile = getUserProfile(accountData);
          setUserProfile(userProfile);
          // Allow only fully verified users.
          if (!isFullyVerified(accountData)) {
            setVerifyRequired(true);
          }
          if (params.consentGrantedPreviously) {
            // Allow and prevent tracking.
            grantAccess('allow', false);
          } else {
            // Allow only fully verified users.
            if (params.clientId) {
              setShowConsentScreen(true);
              setClientApp(params.clientId);

              if (params.clientName) {
                setClientName(params.clientName);
              }
              if (params.clientLogo) {
                setClientLogo(params.clientLogo);
              }
              if (params.scope) {
                setScope(params.scope);
              }
            }
          }
        })
        .finally(() => {
          analytics.page('Consent', {userId: userData?.memberId});
        })

    }
  }, []);

  /**
   * Require users verification if not passed yet.
   */
  useEffect(() => {
    if (userData?.memberId) {
      if (clientApp && clientName) {
        // Track Connection Consent Viewed event.
        analytics.track('Connection Consent Viewed', {
          oauthClientId: clientApp,
          oauthClientName: clientName,
          context: {
            app: {
              name: 'AuthApp',
              namespace: 'components/Hydra/Consent',
            },
          },
          userId: userData?.memberId ?? null,
        });
      }
    }
  }, [userData, clientApp, clientName]);

  //Redirect the user to the verify app
  function doVerify() {
    window.location.href =
      getAppUrl('verify') +
      `?return_to=${encodeURIComponent(window.location.href)}&iframe=1`;
  }

  // Process access.
  function grantAccess(type, trackAnalytics = true) {
    setShowConsentScreen(false);
    const params = {
      action: 'process',
      token: queryParams?.consent_challenge,
      type: type,
      restrictedScope: [],
    };

    // Track only if needed.
    if (trackAnalytics === true) {
      let eventName = 'Connection Consent Accepted';
      if (type === 'deny') {
        eventName = 'Connection Consent Declined';
      }
      analytics.track(eventName, {
        oauthClientId: clientApp,
        oauthClientName: clientName,
        context: {
          app: {
            name: 'AuthApp',
            namespace: 'components/Hydra/Consent',
            build: process.env.REACT_APP_BUILD,
            version: process.env.REACT_APP_VERSION,
          },
        },
        userId: userData?.memberId ?? null,
      });
    }

    doHydraAction(params)
      .then(({ data: return_to }) => {
        if (type === 'deny') {
          // Close on Deny in BrandConnections Consent.
          if (window.opener) {
            window.close();
          }
          else {
            window.location.href = return_to;
          }
        }
        else if (return_to) {
          setConsentRedirectUrl(return_to);
        }
        else {
          setError('Missing consent redirect URL.');
        }
      })
      .catch((error) => {
        setShowConsentScreen(true);
        console.error('Processing user consent error', error.message);
        setError('Error processing consent redirect.');
      });
  }

  if (error) {
    return <Error message={error}/>
  } else if (verifyRequired) {
    return (
      <Box
        p={{ xs: 2, md: 5 }}
        boxShadow={{ xs: 0, sm: 0, md: 6 }}
        className={classes.consentBox}
      >
        <Typography
          variant="h1"
          component="h1"
          className={classes.consentTitle}
        >
          {verifyHeaderText}
        </Typography>
        <Button
          className={classes.consentAllow}
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          onClick={() => doVerify()}
          data-id="consent-verify-button"
        >
          {verifyButtonText}
        </Button>
      </Box>
    );
  } else if (showConsentScreen) {
    return (
      <Box
        p={{ xs: 2, md: 5 }}
        boxShadow={{ xs: 0, sm: 0, md: 6 }}
        className={classes.consentBox}
      >
        <Header logo={clientLogo}/>
        <Typography
          variant="h1"
          component="h1"
          className={classes.consentTitle}
        >
          {consentHeaderText}
        </Typography>
        <Typography
          variant="body1"
          component="ul"
          className={classes.consentList}
        >
          {scope &&
            scope.map((item) => (
              <li key={item?.key} className={classes.consentListItem}>
                {item?.label}
                <span>{item?.message}</span>
              </li>
            ))}
        </Typography>
        <Button
          className={classes.consentAllow}
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          onClick={() => grantAccess('allow')}
          data-id="consent-allow-access-button"
        >
          {allowAccessText}
        </Button>
        <Button
          className={classes.consentDeny}
          fullWidth
          onClick={() => grantAccess('deny')}
          data-id="consent-deny-access-button"
        >
          {denyAccessText}
        </Button>
      </Box>
    );
  } else if (consentRedirectUrl && hydraParams) {
    return <CallbackScreen
      userData={userProfile}
      endProcessing={() => window.location.href=consentRedirectUrl}
      cancelProcessing={() => grantAccess('deny')}
      clientId={hydraParams.clientId}
      consentRedirectUrl={consentRedirectUrl}
    />
  }
  else {
    return <Preloader title={"Loading consent"} />
  }
};

export default Consent;
