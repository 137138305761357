import {startPage} from "../const/const";

/**
 * Set error helper.
 *
 */
export const endPopupProcessing = (userData, error = null) => {
  if (!window.localStorage) {
    console.error("localStorage not supported")
    return
  }

  let data = {}
  const startPageValue = window.localStorage.getItem(startPage)
  if (startPageValue) {
    if (error) {
      const errorMessage = typeof error === "string" ? error : error?.message
      data = {
        error: errorMessage
      }
    } else if (userData?.access_token) {
      data = {access_token: userData?.access_token}
    } else {
      data = {
        error: "Unknown error"
      }
    }
  } else {
    console.error("No start page defined.")
    return
  }

  try {
    if (window.opener && (data?.error || data?.access_token)) {
      const url = new URL(startPageValue)
      window.opener.postMessage(data, url.origin)
      window.close()
    }
  } catch (error) {
    console.error("Error processing redirect", error)
  }
}