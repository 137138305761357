/**
 * Helper to get all needed verifications.
 *
 * @param {any} accountData
 * @return {{
 *   sduMilitaryStatus: *,
 *   veriffStatus: *,
 *   emailStatus: *,
 *   militaryStatus: *,
 *   identityStatus: *,
 *   lighthouseStatus: *,
 *   sduIdentityStatus: *,
 *   quizStatus: *,
 *   proveStatus: *
 * }}
 */
export const getAllIdentificationStatuses = (accountData) => {
  return {
    identityStatus: accountData?.identification?.identity?.status,
    militaryStatus: accountData?.identification?.military?.status,
    emailStatus:
    accountData?.identification?.military?.verification?.email?.status,
    lighthouseStatus:
    accountData?.identification?.military?.verification?.lighthouse?.status,
    quizStatus:
    accountData?.identification?.military?.verification?.quiz?.status,
    sduMilitaryStatus:
    accountData?.identification?.military?.verification?.manual?.status,
    sduIdentityStatus:
    accountData?.identification?.identity?.verification?.manual?.status,
    veriffStatus:
    accountData?.identification?.identity?.verification?.veriff?.status,
    proveStatus:
    accountData?.identification?.identity?.verification?.prove?.status,
  };
};

/**
 * Helper to decide if user is fully verified.
 *
 * @param {any} accountData
 * @return {boolean|*}
 */
export const isFullyVerified = (accountData) => {
  const {militaryStatus, identityStatus} =
    getAllIdentificationStatuses(accountData);
  return militaryStatus === "verified" && identityStatus === "verified";
};
